* {
  margin: 0;
  padding: 0;
}
h1 {
  color: #4286f4;
  margin-top: 30px;
  margin-bottom: 60px;
}
.serach-container {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
input {
  width: 100%;
  margin: 10px;
  padding: 18px 10px;
  border-radius: 4px;
  border: 1px solid #4e5766;
  box-shadow: 1px 0.5px #888888;
  font-size: 18px;
}
button {
  padding: 12px 10px;
  width: 30%;
  border-radius: 4px;
  background-color: #4286f4;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
}
.seacrh-card {
  width: 370px;
}
.data {
  font-weight: 600 !important;
  font-size: 16px;
}

.card-title {
  margin-bottom: 0.75rem;
  font-size: 24px;
}

.card-text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 10px;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  flex-direction: row;
}

.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  pointer-events: none;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
  padding: 30px;
}
.main {
  width: 57%;
  margin: 10px auto;
}

.no-resultp {
  font-size: 20px;
  text-align: center;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.btn-secondary {
  width: 50px !important;
  background-color: #0076be !important;
}

.search-input {
  height: 60px !important;
  margin: 0 !important;
}
.transparent-button {
  background-color: transparent;
}
.card-img {
  width: 100% !important;
  height: 100% !important;
}
.container {
  justify-content: center !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.page-header-logo {
  height: 45px;
  margin: 10px 0 0 10px;
}

.page-content {
  flex: 1;
}

.footer {
  background-color: #0076be;
  font-size: 12px;
}

.icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: white;
}

.social-icon {
  width: 14px;
  height: 14px;
}

.footer-line {
  white-space: nowrap;
}

.card {
  width: 860px;
  max-width: 100%;
  border: none;
  transition: transform 0.2s;
}

.my-awesome-div {
  cursor: pointer;
}

.no-resultp {
  margin-top: 20px;
  font-size: 1.2em;
}

.pdf-thumbnail-wrapper {
  width: 310px;
  max-width: 100%;
  height: 210px;
  max-height: 100%;
  cursor: pointer;
  overflow: hidden;
  border: none;
}

.pdf-thumbmail {
  pointer-events: none;
  border: none;
  display: block;
  overflow: hidden;
  scrollbar-width: none;
}

.pdf-thumbnail__page {
  border: 1px solid #686868;
  margin: 16px 0px 0px;
  cursor: pointer;
}

.pdf-thumbmail::-webkit-scrollbar {
  display: none;
}

@media (max-width: 767px) {
  .footer-line {
    white-space: normal;
  }
}

@media only screen and (max-width: 855px) {
  .main {
    width: 94%;
    margin: 10px auto;
  }
}

@media (max-width: 640px) {
  .card {
    width: 90%;
    max-width: 400px;
    padding: 12px;
  }

  .pdf-thumbnail-wrapper {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 400px) {
  .card {
    width: 95%;
    max-width: 350px;
  }

  .pdf-thumbnail-wrapper {
    width: 100%;
    height: 100%;
  }
}

.col-centered {
  float: none;
  margin-right: auto;
  margin-left: auto;
}
